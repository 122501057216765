import { useEffect } from 'react';
import { State } from 'router5';
import { takeUntil } from 'rxjs/operators';
import { Case } from '@proliance-ai/utilities';
import { kebabCaseToSnakeCase } from '@proliance-ai/design-system';
import { routerStateReplaced$, setLastPageStorageValue } from '@router';
import { IUseTabsRouteHandling } from './useTabsRouteHandling.typings';

export const useTabsRouteHandling = <T>(options: IUseTabsRouteHandling<T>): void => {
  const {
    routeName,
    defaultTab,
    tabState: [tab, setTab],
    unsubscribe$
  } = options;
  useEffect(() => {
    routerStateReplaced$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value: State): void => {
        setLastPageStorageValue(value.path);
        if (value.name === routeName && tab !== defaultTab) {
          setTab(defaultTab);
        } else if (value.name === `${routeName}Tab`) {
          const routeTab: null | T = !!value.params.routeTab
            ? kebabCaseToSnakeCase(value.params.routeTab, Case.upper) as T
            : null;
          if (!!routeTab && routeTab !== tab) {
            setTab(routeTab);
          }
        }
      });
  }, [tab]);
}
