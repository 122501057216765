import { ComponentListType, ComponentPermissionData } from '@proliance-ai/typings';
import { routeTabMapping, TabsRouteList } from '@router/data';
import { snakeCaseToCamelCase } from '@proliance-ai/utilities';
import { checkPermission } from './checkPermission';

export const getAcceptedRouteTabList = <T extends string>(
  name: TabsRouteList,
  permission?: ComponentPermissionData
): T[] => {
  const mappingName = name.endsWith('Modal')
    ? name.slice(0, -5) as TabsRouteList
    : name;
  const { routeList, mapper } = routeTabMapping[mappingName];
  return (routeList as T[])
    .filter((item: T) => checkPermission(
      mapper
        ? mapper[item]
        : snakeCaseToCamelCase(item) as ComponentListType,
      permission
    ));
};
