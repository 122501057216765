export type ExternalRouteListType = 'dashboard' | 'risk' | 'dataBreach' | 'courseManagement' | 'myCourses';
export type RouteListType = keyof typeof routeList;
export type FullRouteListType = ExternalRouteListType | RouteListType;

export enum routeList {
  notAllowed = '/error/403',
  notFound = '/error/404',
  errorPage = '/error/:type<document>/:context<topic|id>/:id<[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}>',

  default = '/?id',
  login = '/login?error&email&companyId&redirectUrn&externalRedirect&id',
  sso = '/login/:ssoProvider<entra|google>',
  forgot = '/forgot?email',
  reset = '/set-password?token',
  accountActivation = '/account-activation?token',
  courseInvitation = '/course-invitation?token',

  imprint = '/imprint',
  privacyPolicy = '/privacy-policy',
  genderNotice = '/gender-notice',

  consent = '/consent',
  companies = '/companies',
  products = '/products',

  onboarding = '/onboarding',

  organization = '/organization',
  organizationTab = '/organization/:routeTab<management|business|business-premises|security>',
  organizationTabModal = '/organization/business-premises/:openModal<add|[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}>',

  profile = '/profile',
  profileTab = '/profile/:routeTab<settings|email>?showConsent',

}
