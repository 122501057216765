import { Observable } from 'rxjs';
import { BusinessPremise, BusinessPremiseData, Collection, SystemsApiUrl } from '@proliance-ai/typings';
import { SystemsEntity } from '@services';
import { Api } from '@services/api';

const url = '/api/survey/system';

class SystemsApiService extends Api {
  public getSystemsByType<T extends SystemsEntity>(url: SystemsApiUrl, parameters = {}): Observable<Collection<T>> {
    return this.get<Collection<T>>({
      url,
      parameters
    });
  }

  public getBusinessPremiseById(id: string): Observable<BusinessPremise> {
    return this.get<BusinessPremise>({
      url: `business_premise/${ id }`
    });
  }

  public createBusinessPremise(body: BusinessPremiseData): Observable<BusinessPremise> {
    return this.post<BusinessPremise>({
      url: 'business_premise',
      body
    });
  }

  public updateBusinessPremise(id: string, body: BusinessPremise): Observable<BusinessPremise> {
    return this.put<BusinessPremise>({
      url: `business_premise/${ id }`,
      body
    });
  }

  public deleteBusinessPremise(id: string): Observable<void> {
    return this.delete<void>({
      url: `/api/survey/company-data/business_premise/${ id }`,
      ignoreApiUrl: true
    });
  }
}

export const systemsApiService = new SystemsApiService(url);
