import { Observable } from 'rxjs';
import { ITableState } from '@proliance-ai/design-system';
import { SystemsEntity } from '@app/services';
import {
  Collection,
  BusinessPremise,
  BusinessPremiseData
} from '@proliance-ai/typings';

export enum Systems {
  BUSINESS_PREMISE = 'BUSINESS_PREMISE',
  ASSOCIATED_COMPANY = 'ASSOCIATED_COMPANY',
  WEBSITE = 'WEBSITE',
  FREELANCER = 'FREELANCER',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  PRINCIPAL = 'PRINCIPAL',
  DATA_STORAGE_SELF_HOSTED = 'DATA_STORAGE_SELF_HOSTED',
  DATA_STORAGE_THIRD_PARTY_HOSTED = 'DATA_STORAGE_THIRD_PARTY_HOSTED',
  DATA_STORAGE_CLOUD_HOSTED = 'DATA_STORAGE_CLOUD_HOSTED',
  SOFTWARE = 'SOFTWARE',
}

export type SystemsType = keyof typeof Systems;

export interface SystemsService {
  getCurrentTime: () => Observable<string>;
  getSystemsByType: <T extends SystemsEntity>(
    value: string,
    tableState?: Partial<ITableState<T>>
  ) => Observable<null | Collection<T>>;
  getBusinessPremiseById: (id: string) => Observable<BusinessPremise>;
  createBusinessPremise: (body: BusinessPremiseData) => Observable<BusinessPremise>;
  updateBusinessPremise: (id: string, body: BusinessPremise) => Observable<BusinessPremise>;
  deleteBusinessPremise: (id: string) => Observable<void>;
}
