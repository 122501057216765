import React, { ChangeEvent, FC, InputHTMLAttributes, ReactElement } from 'react';
import { useDataAttributes } from '@proliance-ai/react-ui';
import { ButtonSize, classNameArrayToString, DataAttributesType } from '@proliance-ai/design-system';

import styles from './ToggleButton.styl';

export interface ToggleButtonData {
  text: string;
  value: any;
}

interface OwnProps {
  prefix?: string;
  label?: string;
  useFormGroup?: boolean;
  data: ToggleButtonData[];
  value?: any;
  size?: ButtonSize;
  readOnly?: boolean;
  onChange: (value: string) => void;
  dataAttributesDictionary?: DataAttributesType;
}

type CustomInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'>;

type Props = OwnProps & CustomInputProps;
const ToggleButton: FC<Props> = ({
  prefix = '',
  name = 'button',
  label,
  useFormGroup = false,
  size,
  data,
  disabled,
  readOnly,
  value,
  onChange,
  dataAttributesDictionary = {},
  ...props
}) => {
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const identifier = prefix
    ? prefix + name
    : name;
  const dataAttributesDefaultValue = { toggleButton: identifier };
  const {
    test = dataAttributesDefaultValue,
    guide = dataAttributesDefaultValue
  } = dataAttributesDictionary;
  const dataAttributes = useDataAttributes({ test, guide });

  const buttonList = data
    .map(
      (item: ToggleButtonData, index: number): ReactElement => {
        const checked = value === item.value || !value && !item.value;
        const key = typeof prefix === 'undefined'
          ? `${ name }-${ index }`
          : `${ prefix }-${ name }-${ index }`;
        const className = classNameArrayToString([
          styles.label,
          size && styles[size],
          checked ? styles.primary : styles.default,
          disabled && styles.disabled
        ]);
        return (
          <li className={ styles.listItem } key={ key }>
            <input
              className={ styles.input }
              type="radio"
              name={ name }
              id={ key }
              disabled={ disabled }
              readOnly={ readOnly }
              checked={ checked }
              onChange={ changeHandler }
              value={ item.value }
            />
            <label className={ className } htmlFor={ key }>
              { item.text }
            </label>
          </li>
        );
      }
    );

  const isRequired = props.required && !(readOnly || disabled);
  const labelClassName = classNameArrayToString([
    styles.formLabel,
    isRequired && styles.required
  ]);
  const labelElement = label
    ? (
      <label className={ labelClassName }>
        { label }
      </label>
    )
    : null;

  const className = classNameArrayToString([
    props.className,
    useFormGroup
      ? [ styles.formGroup ]
      : [ styles.noFormGroup ]
  ]);

  return (
    <div className={ className }>
      { labelElement }
      <ul
        className={ styles.list }
        { ...dataAttributes }
      >{ buttonList }</ul>
    </div>
  );
};

export default ToggleButton;
