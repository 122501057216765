import { FC } from 'react';
import { ActivationFn, ActivationFnFactory, State } from 'router5';
import { loadingIndicatorService, showMiddlewareReloadModal } from '@proliance-ai/design-system';
import {
  ComponentType,
  ComponentTypeDictionary,
  IComponentPromiseFactory,
  IExportFC,
  RouteListType,
  RouteState,
  routerDependencies
} from '@router';
import { permissionService } from '@services';

const getComponent = (componentData: ComponentTypeDictionary): null | ComponentType => {
  return (Object.keys(componentData) as RouteListType[]).reduce(
    (result: null | ComponentType, route: RouteListType) => {
      if (result !== null) {
        return result;
      }
      return permissionService.isPageAvailable(route) ? (componentData[route] as ComponentType) : result;
    },
    null
  );
};

export const componentMiddleware: ActivationFnFactory = (): ActivationFn => (toState: State): boolean | Promise<boolean> => {
  const { error: errorRouteState, name } = toState as RouteState;
  if (errorRouteState && !(name === 'login' && errorRouteState === 401)) {
    return true;
  }
  const { component: componentData, dynamic, lazy } = routerDependencies[name];
  const component = dynamic ? getComponent(componentData as ComponentTypeDictionary) : (componentData as ComponentType);
  if (component === null) {
    return true;
  }
  if (!lazy) {
    (toState as RouteState).component = component as FC<any>;
    return true;
  } else {
    return (component as IComponentPromiseFactory<any>)()
      .then((module: IExportFC<any>) => {
        (toState as RouteState).component = module.default;
        return true;
      })
      .catch((error: any) => {
        console.groupCollapsed('ChunkLoadError handled');
        console.error(error);
        console.groupEnd();
        loadingIndicatorService.hide();

        const isChunkLoadError = /Loading chunk [^]+ failed/.test(error.message);
        if (isChunkLoadError) {
          showMiddlewareReloadModal(toState);
        }
        return false;
      });
  }
};
